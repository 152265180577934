.highlight { overflow-x: auto;background: #ffffff; color: black; padding: 20px;border: 1px solid #ccc;}
.highlight pre  { white-space: pre;  font-size: 0.8em;line-height: 1.2em;  color: #d0d0d0; }
.highlight pre code {font-family: 'Source Code Pro', monospace;}
.highlight .c { color: #999988; font-style: italic; }
.highlight .err { background-color: #e3d2d2; color: #a61717; }
.highlight .k { font-weight: bold; }
.highlight .o { font-weight: bold; }
.highlight .cm { color: #999988; }
.highlight .cp { color: #999999; font-weight: bold; }
.highlight .c1 { color: #999988; }
.highlight .cs { color: #999999; font-weight: bold; }
.highlight .gd { background-color: #ffdddd; color: #000000; }
.highlight .gd .x { background-color: #ffaaaa; color: #000000; }
.highlight .ge { font-style: italic; }
.highlight .gr { color: #aa0000; }
.highlight .gh { color: #999999; }
.highlight .gi { background-color: #ddffdd; color: #000000; }
.highlight .gi .x { background-color: #aaffaa; color: #000000; }
.highlight .go { color: #888888; }
.highlight .gp { color: #555555; }
.highlight .gs { font-weight: bold; }
.highlight .gu { color: #800080; font-weight: bold; }
.highlight .gt { color: #aa0000; }
.highlight .kc { font-weight: bold; }
.highlight .kd { font-weight: bold; }
.highlight .kn { font-weight: bold; }
.highlight .kp { font-weight: bold; }
.highlight .kr { font-weight: bold; }
.highlight .kt { color: #445588; font-weight: bold; }
.highlight .m { color: #009999; }
.highlight .s { color: #d14; }
.highlight .na { color: #008080; }
.highlight .nb { color: #0086B3; }
.highlight .nc { color: #445588; font-weight: bold; }
.highlight .no { color: #008080; }
.highlight .ni { color: #800080; }
.highlight .ne { color: #990000; font-weight: bold; }
.highlight .nf { color: #990000; font-weight: bold; }
.highlight .nn { color: #555555; }
.highlight .nt { color: #000080; }
.highlight .nv { color: #008080; }
.highlight .ow { font-weight: bold; }
.highlight .w { color: #bbbbbb; }
.highlight .mf { color: #009999; }
.highlight .mh { color: #009999; }
.highlight .mi { color: #009999; }
.highlight .mo { color: #009999; }
.highlight .sb { color: #d14; }
.highlight .sc { color: #d14; }
.highlight .sd { color: #d14; }
.highlight .s2 { color: #d14; }
.highlight .se { color: #d14; }
.highlight .sh { color: #d14; }
.highlight .si { color: #d14; }
.highlight .sx { color: #d14; }
.highlight .sr { color: #009926; }
.highlight .s1 { color: #d14; }
.highlight .ss { color: #990073; }
.highlight .bp { color: #999999; }
.highlight .vc { color: #008080; }
.highlight .vg { color: #008080; }
.highlight .vi { color: #008080; }
.highlight .il { color: #009999; }
.highlight .gc { background-color: #EAF2F5; color: #999; }
